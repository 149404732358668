import type { IEntity } from '@core/infrastructure/entity/types';

import type { TISODateString, Id } from '@domain/types';

export abstract class Entity<TEntityDTO> implements IEntity<TEntityDTO> {
	readonly #id: Id;

	protected readonly createdAt: TISODateString;
	protected modifiedAt: TISODateString;

	constructor(id: Id, createdAt: TISODateString, modifiedAt?: TISODateString) {
		this.#id = id;

		this.createdAt = createdAt;
		this.modifiedAt = modifiedAt ?? createdAt;
	}

	public get id(): Id {
		return this.#id;
	}

	public abstract toObject(): TEntityDTO;
}
