import { ELocaleCode } from '@core/infrastructure/intl/enums';

export const expenseFormI18n = {
	[ELocaleCode.RU_RU]: {
		submitButton: '→',
	},
	[ELocaleCode.EN_US]: {
		submitButton: '→',
	},
};
