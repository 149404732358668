import { ELocaleCode } from '@core/infrastructure/intl/enums';

export const expensesByCategoryI18n = {
	[ELocaleCode.RU_RU]: {
		hideButton: 'Свернуть ↑',
		showButton: 'Ещё ↓',
	},
	[ELocaleCode.EN_US]: {
		hideButton: 'Hide ↑',
		showButton: 'More ↓',
	},
};
