export const enum EAppPath {
	MAIN = '/',

	EXPENSES = '/list',
	EXPENSES_LIST_MONTHLY = '/list-monthly',

	SIGNUP = '/signup',
	SIGNIN = '/signin',
	SETTINGS = '/settings',
}
