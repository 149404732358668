import { ELocaleCode } from '@core/infrastructure/intl/enums';

export const moneySpentYearI18n = {
	[ELocaleCode.RU_RU]: {
		title: 'Расходы за год',
	},
	[ELocaleCode.EN_US]: {
		title: 'Yearly expenses',
	},
};
