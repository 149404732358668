import { ELocaleCode } from '@core/infrastructure/intl/enums';

export const errorScreenI18n = {
	[ELocaleCode.RU_RU]: {
		defaultTitle: 'Ошибка',
	},
	[ELocaleCode.EN_US]: {
		defaultTitle: 'Error',
	},
};
