import { ELocaleCode } from '@core/infrastructure/intl/enums';

export const errorI18n = {
	[ELocaleCode.RU_RU]: {
		mainPageButtonLabel: 'На главную',
	},
	[ELocaleCode.EN_US]: {
		mainPageButtonLabel: 'Go Home',
	},
};
