import { ELocaleCode } from '@core/infrastructure/intl/enums';

export const expenseListMonthlyI18n = {
	[ELocaleCode.RU_RU]: {
		title: 'Расходы',
	},
	[ELocaleCode.EN_US]: {
		title: 'Expenses',
	},
};
