import { ELocaleCode } from '@core/infrastructure/intl/enums';

export const componentInputI18n = {
	[ELocaleCode.RU_RU]: {
		commentPlaceholder: 'Комментарий',
	},
	[ELocaleCode.EN_US]: {
		commentPlaceholder: 'Comment',
	},
};
